<template>
  <div>
    <v-col v-if="slides.length === 0" class="d-flex flex-column justify-center align-center" cols="12">
      <v-img src="../../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
      <h5 class="pa-0">Carregando...</h5> 
    </v-col>

    <carousel :slides="slides" :height="330" />
  </div>
</template>

<script>
import newsService from "../../../service/newsService";

export default {
  name: "DestaquesHome",
  methods: {
    openPost(id) {
      // this.$router.push({
      //   name: "Post",
      //   params: { id: id, text: "Notícia", href: "/news", type: "news" }
      // });

      this.$router.push({ path: `/post/news/${id}`})

    },
    getNews() {
      newsService.get({ spotlight: true, limit: 2 }).then(res => {
        const data = res.data;
        this.spotlight = data.map(el => {
           return {
              id: el.id,
              title: el.title,
              category: el.category,
              date: new Date(el.created_at).toLocaleString(),
              url: el.path_img_card
        };
        })
      });

      newsService.get({ limit: 7 }).then(res => {
        const news = res.data.map(card => {
          return {
            id: card.id,
            title: card.title,
            category: card.category,
            date: new Date(card.created_at).toLocaleString(),
            url: card.path_img_card
          };
        });

        news.forEach((newsCard, index) => {
          if (index <= 2) {
            this.slides.push(newsCard);
          } else if (index > 2 && index < 7) {
            this.newsHome.push(newsCard);
          }
        });
      });
    }
  },
  mounted() {
    this.getNews();
  },
  components: {
    Carousel: () => import("../../../components/commons/Carousel.vue"),
    Card: () => import("../../../components/commons/Card.vue"),
  },
  data() {
    return {
      slides: [],
      newsHome: [],
      spotlight: [{
        id: 0,
        title: "",
        category: "",
        date: "",
        url: ""
      },{
        id: 1,
        title: "",
        category: "",
        date: "",
        url: ""
      }]
    };
  }
};
</script>

<style>
</style>